import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import YouTube from 'react-youtube'
import css from "@css/projects/p-video-background.module.styl"

function YoutubeBackground({ videoId })
{
    const q = useStaticQuery(graphql`
        query {
            dot : file(relativePath: { eq: "hero__img__dot.png" }) {
                childImageSharp { fixed( width: 2, height: 2) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }
        }
    `)

    const videoOptions = {
        playerVars: { // https://developers.google.com/youtube/player_parameters
            autoplay: 1,
            controls: 0,
            rel: 0,
            showinfo: 0,
            fs: 0,
            modestbranding: 1,
            loop: 1,
            playsinline: 1,
            iv_load_policy: 3,
        }
    };

    const onReady = (event) => {
        // access to player in all event handlers via event.target
        event.target.mute();
        event.target.playVideo();
    }

    const onEnd = (event) => {
        event.target.playVideo();
    }
    return (
        <div className={css.pVideoBackground}>
            <div className={css.pVideoBackground__overlay} style={{ backgroundImage: `url(${q.dot.childImageSharp.fixed.base64})` }}/>
            <div className={css.pVideoBackground__inner}>
                <YouTube
                    videoId={videoId}
                    opts={videoOptions}
                    className={css.pVideoBackground__iframe}
                    onReady={onReady}
                    onEnd={onEnd}
                />
            </div>
        </div>
    )
}

export { YoutubeBackground }